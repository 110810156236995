<template>
  <asignaturas-form
    :nombreModal="nombreModal"
    title="Crear asignatura"
    :asignatura="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapGetters, mapState } from 'vuex'

// HIJOS
import AsignaturasForm from './AsignaturasForm.vue'

export default {
  components: {
    // COMPONENTES
    AsignaturasForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        nombre: '',
        tipo: null,
        orden: null,
        id_curso: null,
        promedio: 1,
        prioritaria: 0,
        horas: null,
        horas_asignadas: '0.0',
        id_curso: null,
        id_estado: 1,
      },
    }
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapState('cursos', ['selectedCurso']),
    ...mapState('asignaturas', ['response']),
    ...mapGetters({
      getAsignaturasCurso: 'asignaturas/getAsignaturasCurso',
    }),
  },
  methods: {
    ...mapActions({
      createAsignatura: 'asignaturas/createAsignatura',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    agregar(asignatura) {
      if (asignatura.orden === null) {
        asignatura.orden = this.getAsignaturasCurso.length + 1
      }
      asignatura.id_curso = this.selectedCurso.id
      this.createAsignatura(asignatura).then(() => {
        if (this.response.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asignatura guardada 👍',
              icon: 'CheckIcon',
              text: 'La asignatura fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.fetchAsignaturasCurso(this.selectedCurso.id)
          this.$bvModal.hide(this.nombreModal)
          this.resetData()
        }
        else {
          this.$swal({
            title: 'Error!',
            text: this.response.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    resetData() {
      this.data.nombre =  ''
      this.data.tipo =  null
      this.data.orden =  null
      this.data.id_curso =  null
      this.data.promedio =  1
      this.data.prioritaria =  0
      this.data.horas =  null
      this.data.horas_asignadas =  '0.0'
      this.data.id_curso =  null
      this.data.id_estado =  1
    },
  },
}
</script>
