<template>
  <b-button-group>

    <!-- EDITAR en MODAL -->
    <b-button
      v-if="nombreModal !== 'false' && $can('update', modulo)"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal="nombreModal"
      :variant="variant"
      class="btn-sm mt-25 btn-icon rounded mr-50"
      :title="'Configuraciones de ' + title"
      :disabled="!btnEnabled"
    >
      Configraciones
    </b-button>

  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    nombreModal: {
      type: String,
      default: 'false',
    },
    title: {
      type: String,
      default: '',
    },
    btnEnabled: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    'item.id_configuracion': {
      handler(newVal, oldVal) {
        this.setColorBtn()
      }
    }
  },
  data() {
    return {
      moduloCreado: '',
      variant: 'outline-secondary',
    }
  },
  mounted() {
    this.setColorBtn()
  },
  methods: {
    setColorBtn() {
      if (this.btnEnabled) {
        this.variant = this.item.id_configuracion ? 'success' : 'primary'
      }
    },
  },
}
</script>
