<template>
  <b-card aria-hidden="true">
    <b-row>
      <b-col
        lg="4"
        cols="12"
        md="12"
        sm="12"
      >
        <b-card-title>
          <div class="mt-75 ml-75">{{ selectedCurso.nombre }} {{ selectedCurso.letra }}</div>
        </b-card-title>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        md="12"
        sm="12"
        class="text-center text-lg-right mb-1"
      >
      </b-col>
      <b-col
        cols="12"
        lg="2"
        md="12"
        sm="12"
      >
        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Crear"
          modal="modal-asignatura-create"
          texto="Asignatura"
          :modulo="configs.permiso"
        />
        <asignaturasCreate
          nombreModal="modal-asignatura-create"
        />
      </b-col>
    </b-row>

    <b-overlay
      :show="getLoading"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-table
        striped
        small
        hover
        noCollapse
        bordered
        class="rounded"
        responsive
        :fields="fields"
        :items="items"
        :busy="getLoading"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :sort-direction="table.sortDirection"
        @filtered="onFiltered"
      >

        <!-- NOMBRE -->
        <!-- <template #cell(nombre)="data">
          <b-form-input
            :id="`nombre-${data.item.id}`"
            :style="{ display: configs.visibleInputs.includes(`nombre-${data.item.id}`) ? 'block' : 'none' }"
            v-model="data.item.nombre"
            placeholder="Ingresa el nombre"
            :state="null"
            @blur="clickColumn('input', `nombre-${data.item.id}`)"
          />
          <div
            :id="`div-nombre-${data.item.id}`"
            :style="{ cursor: 'pointer', display: configs.visibleInputs.includes(`nombre-${data.item.id}`) ? 'none' : 'block' }"
            @click="clickColumn('div', `nombre-${data.item.id}`)"
          >
            {{ data.item.nombre }}
          </div>
        </template> -->

        <!-- TIPO -->
        <template #cell(tipo)="data">
          {{ getTiposAsignatura.find(t=> t.value === data.item.tipo).text }}
        </template>

        <!-- HORAS -->
        <!-- <template #cell(horas)="data">
          <b-form-input
            :id="`horas-${data.item.id}`"
            :style="{ display: configs.visibleInputs.includes(`horas-${data.item.id}`) ? 'block' : 'none' }"
            v-model="data.item.horas"
            placeholder="Ingresa las horas"
            :state="null"
            @blur="clickColumn('input', `horas-${data.item.id}`)"
          />
          <div
            :id="`div-horas-${data.item.id}`"
            :style="{ cursor: 'pointer', display: configs.visibleInputs.includes(`horas-${data.item.id}`) ? 'none' : 'block' }"
            @click="clickColumn('div', `horas-${data.item.id}`)"
          >
            {{ data.item.horas }}
          </div>
        </template> -->

        <!-- PRIORITARIA -->
        <template #cell(prioritaria)="data">
          <b-form-checkbox
            v-model="data.item.prioritaria"
            :disabled="true"
          />
        </template>

        <!-- ESTADO -->
        <template #cell(estado)="data">
        </template>

        <!-- CONFIGS -->
        <template #cell(configuraciones)="data">
          <colConfiguraciones
            title="asignatura"
            :modulo="configs.permiso"
            :nombreModal="`m-c-asignatura-${data.item.id}`"
            :item="data.item"
            :btnEnabled="data.item.tipo === 2 || data.item.tipo === 3"
          />
          <asignaturasConfiguraciones
            v-if="data.item.tipo === 2 || data.item.tipo === 3"
            :nombreModal="`m-c-asignatura-${data.item.id}`"
            :asignatura="{
              id: data.item.id,
              nombre: data.item.nombre,
              tipo: data.item.tipo,
            }"
          />
        </template>

        <!-- ACTIONS -->
        <template #cell(acciones)="data">
          <colAccionesBtnes
            :modulo="configs.permiso"
            :modal="`m-u-asignatura-${data.item.id}`"
            :data="data"
            title="asignatura"
            @processRemove="removeAsignatura"
          />

          <asignaturasUpdate
            :nombreModal="`m-u-asignatura-${data.item.id}`"
            :item="data.item"
          />
        </template>
      </b-table>
    </b-overlay>

  </b-card>
</template>

<script>

// Etiquetas //
import {
  BOverlay, BCard, BTable, BRow, BCol, BFormInput, BFormInvalidFeedback,
  BFormCheckbox, BCardTitle,
} from 'bootstrap-vue'

import { mapGetters, mapActions, mapState } from 'vuex'

// RECICLADOS
import btnCrearModal from '../../../components/List/btnCrearModal.vue'
import colAccionesBtnes from '../../../components/List/colAccionesBtnes.vue'

// HIJOS
import AsignaturasCreate from './AsignaturasCreate.vue'
import AsignaturasUpdate from './AsignaturasUpdate.vue'
import colConfiguraciones from './components/colConfiguraciones.vue'
import AsignaturasConfiguraciones from './AsignaturasConfiguraciones.vue'

export default {
  components: {
    BOverlay, BCard, BTable, BRow, BCol, BFormInput, BFormInvalidFeedback,
    BFormCheckbox, BCardTitle,
    // RECICLADOS
    btnCrearModal,
    colAccionesBtnes,

    // HIJOS
    AsignaturasCreate,
    AsignaturasUpdate,
    AsignaturasConfiguraciones,
    colConfiguraciones,
  },
  data() {
    return {
      items: [],

      optionsTipos: [
        {
          id: 1,
          nombre: 'Normal'
        },
        {
          id: 2,
          nombre: 'Pertenece a una asignatura compuesta'
        },
        {
          id: 3,
          nombre: 'Taller'
        },
        {
          id: 4,
          nombre: 'Por Conceptos'
        },
        {
          id: 5,
          nombre: 'Compuesta'
        }
      ],
      configs: {
        permiso: 'asignaturas',
        visibleInputs: [],
        visibleDivs: [],
      },
      table: {
        filter: '',
        filterOn: [],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
      },

      fields: [
        {
          key: 'orden',
          label: 'Orden',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '0% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '30% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'tipo',
          label: 'tipo asignatura',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horas',
          label: 'horas semanales programa estudio',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horas_asignadas',
          label: 'horas semanales asignadas',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'prioritaria',
        //   label: 'priorizada',
        //   sortable: false,
        //   tdClass: 'text-center',
        //   thStyle: {
        //     'text-align': 'center',
        //     width: '0% !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [
        {
          key: 'configuraciones',
          label: 'configuraciones',
          tdClass: 'text-center',
          thStyle: {
            width: '5% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '5% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState('cursos', ['selectedCurso']),
    ...mapGetters({
      getLoading: 'asignaturas/getLoading',
      getAsignaturasCurso: 'asignaturas/getAsignaturasCurso',
      getTiposAsignatura: 'asignaturas/getTiposAsignatura',
    }),
  },
  watch: {
    getAsignaturasCurso(getAsignaturasCurso) {
      this.items = []
      this.items = this.getAsignaturasCurso
    },
  },
  mounted() {
    this.setTablePermisos()
    this.fetchAsignaturasCurso(this.selectedCurso.id)
  },
  methods: {
    ...mapActions({
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
      removeAsignaturas: 'asignaturas/removeAsignaturas',
    }),
    setTablePermisos() {
      if (this.$can('update', this.configs.permiso)
        || this.$can('delete', this.configs.permiso)
      ) {
        this.fieldAcciones.forEach(element => {
          this.fields.push(element)
        });
      }
    },
    removeAsignatura(id_asignatura) {
      const asignatura = this.items.find(i => i.id === id_asignatura)
      this.$swal({
        title: 'Eliminar asignatura!',
        text: `Estás seguro que deseas eliminar la asignatura
          "${asignatura.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, elimínala!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = {
            id_asignatura,
            id_curso: this.selectedCurso.id,
          }
          this.removeAsignaturas(data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminada con éxito!',
              text: `"${asignatura.nombre}" ha sido eliminada!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // clickColumn(tipo, id) {
    //   if (tipo == 'input') {
    //     const index = this.configs.visibleInputs.indexOf(id);
    //     if (index > -1) {
    //       this.configs.visibleInputs.splice(index, 1);
    //     }
    //   } else if (tipo == 'div') {
    //     const index = this.configs.visibleInputs.indexOf(id);
    //     if (index > -1) {
    //       this.configs.visibleInputs.splice(index, 1);
    //     } else {
    //       this.configs.visibleInputs.push(id);
    //     }
    //   }
    // },
  },
}
</script>
