<template>
  <asignaturas-form
    :nombreModal="nombreModal"
    title="Editar asignatura"
    :asignatura.sync="asignatura"
    @processForm="editar"
  />
</template>

<script>

import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AsignaturasForm from './AsignaturasForm.vue'

export default {
  components: {
    AsignaturasForm,
  },
  data() {
    return {
      asignatura: {},
    }
  },
  computed: {
    ...mapState('cursos', ['selectedCurso']),
    ...mapState('asignaturas', ['response']),
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.initData(this.item)
  },
  methods: {
    ...mapActions({
      updateAsignatura: 'asignaturas/updateAsignatura',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    initData(item) {
      this.asignatura = JSON.parse(JSON.stringify(item));
    },
    editar(asignatura) {
      this.updateAsignatura(asignatura).then(() => {
        if (this.response.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asignatura guardada 👍',
              icon: 'CheckIcon',
              text: 'La asignatura fue editada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$bvModal.hide(this.nombreModal)
          this.fetchAsignaturasCurso(this.selectedCurso.id)
        }
        else {
          this.$swal({
            title: 'Error!',
            text: this.response.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
